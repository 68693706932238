import React from 'react'
import { Global, css } from '@emotion/core'
import { colors, fonts } from '@/utils/preset'
import { desktop, laptop, tablet, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'

const reset = css`
  /* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
  */
  /* stylelint-disable */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

const base = css`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    letter-spacing: 0.05em;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  body {
    font-family: ${fonts.base};
    color: ${colors.black.base};
  }

  * {
    box-sizing: border-box;
  }

  a {
    outline: none;
  }

  picture {
    display: block;
  }

  input,
  option,
  textarea,
  button {
    outline: none;
    border: none;
    border-radius: 0;
    appearance: none;
    padding: 0;
    margin: 0;
    background: none;
  }

  textarea {
    overflow: auto;
    resize: none;
  }

  p {
    word-wrap: break-word;
  }

  hr {
    margin: 0;
    border: none;
  }

  *::before,
  *::after {
    box-sizing: border-box;
  }

  @media ${mq.with(desktop)} {
    br {
      &.mq-laptop, &.mq-tablet, &.mq-mobile {
        display: none;
      }
      &.mq-desktop {
        display: block;
      }
    }
  }
  @media ${mq.with(laptop)} {
    br {
      &.mq-desktop, &.mq-tablet, &.mq-mobile {
        display: none;
      }
      &.mq-laptop {
        display: block;
      }
    }
  }
  @media ${mq.with(tablet)} {
    br {
      &.mq-desktop, &.mq-laptop, &.mq-mobile {
        display: none;
      }
      &.mq-tablet {
        display: block;
      }
    }
  }
  @media ${mq.with(mobile)} {
    br {
      &.mq-desktop, &.mq-laptop, &.mq-tablet {
        display: none;
      }
      &.mq-mobile {
        display: block;
      }
    }
  }
  .grecaptcha-badge {
    display: none !important;
  }
`

const styles = css`
  ${reset};
  ${base};
`

const GlobalStyle = () => (
  <Global styles={styles} />
)
export default GlobalStyle
